import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import numberToWords from "number-to-words";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate1, convertToIndianNumbering } from "../services/api.service";
import html2pdf from "html2pdf.js";
import DocumentTitle from "react-document-title";
import { useContext } from "react";
import { url } from "../index";

function PDF() {

  const baseUrl = useContext(url);
  const { id } = useParams();
  const IDRef = useRef(id);

  useEffect(() => {
    IDRef.current = id;
    getCountriesCD();
  }, [id]);

  const navigate = useNavigate();

  const [number, setNumber] = useState(null);
  const [words, setWords] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const formattedDate = new Date().toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [base64Data, setBase64Data] = useState("");

  const getCountriesWP = async () => {
    ViewWPData.append("AdminID", localStorage.getItem("id"));
    ViewWPData.append("PDF", base64Data);
    ViewWPData.append("Type", "Invoice");
    ViewWPData.append("ID", id);
    try {
      const response = await fetch(
        `${baseUrl}Invoice/WhatsappInvoice`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewWPData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrint = async () => {
    const pdfContent = document.getElementById("pdf-content");
    const pdfOptions = {
      filename: `${InvoiceNo}/Invoice/${formattedDate}.pdf`,
      html2canvas: { scale: 1, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf(pdfContent, pdfOptions).output("blob");
      const arrayBuffer = await pdfBlob.arrayBuffer();

      setBase64Data(
        btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )
      );
    } catch (error) {
      console.error("Error generating or converting PDF:", error);
    }
  };

  useEffect(() => {
    getCountriesWP();
  }, [base64Data]);

  const ViewCDData = new FormData();
  const ViewWPData = new FormData();
  const [optionsCD, setOptionsCD] = useState([]);
  const [optionsD, setOptionsD] = useState([]);
  const [optionsB, setOptionsB] = useState([]);

  ViewCDData.append("ID", id);
  ViewCDData.append("AdminID", localStorage.getItem("id"));
  const getCountriesCD = async () => {
    try {
      const response = await fetch(
        `${baseUrl}Invoice/PrintInvoiceById`,
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();
      setOptionsCD(data.result.Invoice);
      setOptionsB(data.result.bankdetail);
      setOptionsD(data.result.invoicedetail);
      setInvoiceNo(data.result.Invoice[0].InvoiceNo);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (optionsCD && optionsCD.length > 0) {
      setNumber(optionsCD[0].TotalAmount);
      if (number !== null) {
        const words =
          numberToWords.toWords(number).charAt(0).toUpperCase() +
          numberToWords.toWords(number).slice(1);
        setWords(words);
      }
    }

    handlePrint();
  }, [optionsCD]);

  return (
    <DocumentTitle title="PDF | LEGAL CRM">
      <div>
        {optionsCD &&
          optionsCD.map((val, index) => (
            <div className="printWrapper a4" id="pdf-content" key={index}>
              <div className="pagePrint">
                <div className="pageHeader">
                  {val.Template ? (
                    <div style={{ alignItems: "center" }}>
                      <h3
                        style={{
                          fontFamily: "-moz-initial",
                          textAlign: "center",
                        }}
                      >
                        {val.Template}
                      </h3>
                      <p
                        style={{
                          fontFamily: "-moz-initial",
                          textAlign: "center",
                        }}
                      >
                        {val.TemplateMobile}
                      </p>
                      <p style={{ fontFamily: "-moz-initial" , textAlign: "center",}}>
                        {val.TemplateAddress}
                      </p>
                    </div>
                  ) : (
                    <div className="logoPrint">
                      <img
                        src={`https://api.lawyerhunt.in/uploaded/logo/${val.CompanyLogo}`}
                      />
                    </div>
                  )}
                </div>
                <br />
                <div className="printHeading">
                  <h3>INVOICE</h3>
                </div>
              </div>

              <div className="invoiceNum">
                <div className="invoiceNumValue">
                  <strong>
                    Invoice Number : <span>{val.InvoiceNo}</span>
                  </strong>
                </div>
                <div className="invoiceNumValue">
                  <strong>
                    Invoice Date : <span>{formatDate1(val.Date)}</span>
                  </strong>
                </div>
              </div>

              <div className="invoiceDetail">
                <div className="detailBox r_pdf">
                  <div className="printNames">
                    <strong>Case Details:</strong>
                    {val.FileNo}{" "}
                    {val.CaseNo === "" || val.CaseNo === null
                      ? ""
                      : `/ ${val.CaseNo}`}{" "}
                    {val.CaseName === "" || val.CaseName === null
                      ? ""
                      : `/ ${val.CaseName}`}{" "}
                    {val.CaseYear === "" || val.CaseYear === "0000"
                      ? ""
                      : `/ ${val.CaseYear}`}
                  </div>
                  <div className="printNames">
                    <strong>Client Name:</strong>
                    {val.ClientName} {val.ClientLastName}
                  </div>
                  <div className="printNames">
                    <strong>Mobile No.:</strong>
                    <span>{val.ClientMobile}</span>
                  </div>
                  <div className="printNames">
                    <strong>Address:</strong>
                    <span>{val.ClientAddress}</span>
                  </div>
                  <div className="printNames">
                    <strong>Company:</strong>
                    <span>{val.ClientCompany}</span>
                  </div>
                </div>
              </div>

              <div className="tableBox">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "50px" }}>Sr</th>
                      <th style={{ width: "235px" }}>Description</th>
                      <th style={{ width: "200px" }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optionsD &&
                      optionsD.map((val, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <b>{val.Description}</b>
                            </td>
                            <td>
                              <span>{val.Amount}</span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "430px" }} rowspan="3">
                        <strong> Rupee in words:</strong>
                        <p id="word" className="upperCase">
                        {convertToIndianNumbering(val.InvoiceAmount)}
                        </p>
                      </td>
                      <td>
                        <strong>Total Amount</strong>
                      </td>
                      <td>{val.TotalAmount}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Discount</strong>
                      </td>
                      <td>{val.Discount}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Invoice Amount ₹</strong>
                      </td>
                      <td>{val.InvoiceAmount}/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bankDetailBox">
                {optionsB &&
                  optionsB.map((val, index) => {
                    return (
                      <div className="leftDet">
                        <h3>Bank Detail:</h3>
                        <div className="printNames">
                          <strong>Bank Name:</strong>
                          <span>{val.Bank}</span>
                        </div>
                        <div className="printNames">
                          <strong>Branch Name:</strong>
                          <span>{val.Branch}</span>
                        </div>
                        <div className="printNames">
                          <strong>Account Holder's Name:</strong>
                          <span>{val.Beneficiary}</span>
                        </div>
                        <div className="printNames">
                          <strong>Account No.:</strong>
                          <span>{val.AccountNo}</span>
                        </div>
                        <div className="printNames">
                          <strong>IFSC Code.:</strong>
                          <span>{val.IFSCCode}</span>
                        </div>
                      </div>
                    );
                  })}
                <div className="dateandSign">
                  <div className="signature">
                    <h6 style={{ textAlign: "center" }} className="upperCase">
                      {val.Template === null || val.Template === ""
                        ? val.Counsel
                        : val.Template}
                    </h6>
                  </div>
                </div>
              </div>
              {val.Template ? (
                " "
              ) : (
                <div
                  style={{
                    display:
                      (val.CompanyFooter1 === null ||
                        val.CompanyFooter1 === "") &&
                      (val.CompanyFooter2 === null ||
                        val.CompanyFooter2 === "") &&
                      (val.CompanyFooter3 === null || val.CompanyFooter3 === "")
                        ? "none"
                        : "block",
                  }}
                >
                  <hr />
                  <div className="footer">
                    {val.CompanyFooter1 === null ||
                    val.CompanyFooter1 === "" ? (
                      ""
                    ) : (
                      <div className="footer_invoice">
                        <h4>{val.AdminCompany}</h4>
                        <p>{val.CompanyFooter1}</p>
                      </div>
                    )}

                    {val.CompanyFooter2 === null ||
                    val.CompanyFooter2 === "" ? (
                      ""
                    ) : (
                      <div className="footer_invoice">
                        <h4>{val.AdminCompany}</h4>
                        <p>{val.CompanyFooter2}</p>
                      </div>
                    )}

                    {val.CompanyFooter3 === null ||
                    val.CompanyFooter3 === "" ? (
                      ""
                    ) : (
                      <div className="footer_invoice">
                        <h4>{val.AdminCompany}</h4>
                        <p>{val.CompanyFooter3}</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <br />
              <br />
              {/* {val.InvoiceTerms === null || val.InvoiceTerms === "" ? (
                ""
              ) : (
                <div className="termBankDetail">
                  <div className="terms">
                    <h3>Terms & Conditions:</h3>
                    <p>{val.InvoiceTerms}</p>
                  </div>
                </div>
              )} */}
            </div>
          ))}
      </div>
    </DocumentTitle>
  );
}

export default PDF;
