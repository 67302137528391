import React, { useState, useRef } from "react";
import { useHistory } from 'react-router-dom'
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { formatDate1, withUidApi } from "../services/api.service";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import PdfDocument from "./PDF";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import Select from "react-select";
import striptags from "striptags";
import html2pdf from "html2pdf.js";
import {
  viewData,
  deleteUser,
  getClient,
  getLetterTemp,
  userDetails,
  activeStatus,
  permission,
  customStyles,
  getCurrentDate,
} from "../services/api.service";
import Preloader from "./Preloader";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { clearForm } from "../services/api.service";
import DocumentTitle from "react-document-title";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import jsPDF from "jspdf";
import { format } from "date-fns";
import Footer from "./Footer";

import { Link, useParams } from "react-router-dom";

const LetterCD = () => {

    const [saveButton, setSaveButton] = useState(true);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, []);
  
    var modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        [{ direction: "rtl" }],
        [{ font: [] }],
  
        ["clean"][{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
        [
          {
            color: [
              "#000000",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "#9933ff",
              "#ffffff",
              "#facccc",
              "#ffebcc",
              "#ffffcc",
              "#cce8cc",
              "#cce0f5",
              "#ebd6ff",
              "#bbbbbb",
              "#f06666",
              "#ffc266",
              "#ffff66",
              "#66b966",
              "#66a3e0",
              "#c285ff",
              "#888888",
              "#a10000",
              "#b26b00",
              "#b2b200",
              "#006100",
              "#0047b2",
              "#6b24b2",
              "#444444",
              "#5c0000",
              "#663d00",
              "#666600",
              "#003700",
              "#002966",
              "#3d1466",
              "custom-color",
            ],
          },
        ],
      ],
    };
  
    var formats = [
      "header",
      "height",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "color",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
      "size",
      "background",
      "script",
      "video",
    ];
    //        ---------- CLOSE POPUP VARIABLE --------
  
    const closeButton = document.querySelector(".btn_popup");
    const closeEdit = document.querySelector(".e_popup");
    const closeView = document.querySelector(".ab");
    const closeDelete = document.querySelector(".d_lettertemplate");
  
    //        ---------- LETTER TEMPLATE VARIABLE --------
  
    const baseUrl = useContext(url);
    const currentDate = new Date().toISOString().split("T")[0];
    const [optionsEvent, setOptionsEvent] = useState([]);
    const [fCaseId, setFCaseId] = useState("");
    const [fFromDate, setFFromDate] = useState("");
    const ViewCDData = new FormData();

    const [fToDate, setFToDate] = useState("");
    const [fClientID, setFClientID] = useState("");
    const [fClientOption, setFClientOption] = useState([]);
    const [caseData, setCaseData] = useState("");
    const navigate = useNavigate();
    const [dCaseID, setDCaseID] = useState("");
    const [dDate, setDDate] = useState("");
    const [dFileName, setDFileName] = useState("");
    const [ddescription, setDDescription] = useState("");
    const [dletterTemplateID, setDletterTemplateID] = useState("");
    const [loading, setLoading] = useState(true);
    const [CID, setCID] = useState("");
    const [optionsCD, setOptionsCD] = useState("");
    const [isAdd, setIsAdd] = useState("0");
    const [isEdit, setIsEdit] = useState("0");
    const [isView, setIsView] = useState("0");
    const [isDelete, setIsDelete] = useState("0");
    const [isStatus, setIsStatus] = useState("0");
    const [fileData, setFileData] = useState([]);
    const handleProcedureContentChange = (content) => {
      setDescription(content);
    };
  
    const handleProcedureContentChange1 = (content) => {
      setEditDescription(content);
    };
  

    const getCaseHistory = async () => {
        
        ViewCDData.append("ID", id);
        ViewCDData.append("AdminID", localStorage.getItem("id"));
    
        try {
          const response = await fetch(
            `${baseUrl}CaseHistory/ViewCaseHistoryById`,
            {
              method: "POST",
              headers: {
                Authorization: `${localStorage.getItem("access_token")}`,
                Accept: "*/*",
              },
              body: ViewCDData,
            }
          );
    
          const data = await response.json();
    
          if (data && data.result.message == "Session Time Expire.") {
            toast.error("Token expired please login again");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
    
          setOptionsCD(data && data.result[0]);
          setCaseData(data && data.result[0].case[0])
          setCID(data && data.result[0].case[0].Id);
        } catch (error) {
          console.error(error);
        }
      };
    //        ---------- Letter Template Details --------
    const LetterTempf = new FormData();
  
    const handleLettertemp = (e) => {
      setletterTemplateID(e.target.value);
      setEditletterTemplateID(e.target.value);
      const getTempDetails = async () => {
        LetterTempf.append("AdminID", localStorage.getItem("id"));
        LetterTempf.append("ID", e.target.value);
  
        try {
          const response = await fetch(
            `${baseUrl}Master/ViewLetterTemplateById`,
            {
              method: "POST",
              body: LetterTempf,
              headers: {
                Authorization: `${localStorage.getItem("access_token")}`,
                Accept: "*/*",
              },
            }
          );
          const data = await response.json();
          if (data && data.result.message == "Session Time Expire.") {
            toast.error("Token expired please login again");
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
          if (data && data.result[0].code == 200) {
            setEditDescription(data.result[0].Description);
            setDescription(data.result[0].Description);
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      getTempDetails();
    };
  
    //        ---------- ADD VARIABLE --------
  
    const [letterTemplatesData, setLetterTemplatesData] = useState([]);
    const [letterTemplate, setLetterTemplate] = useState("");
    const [Template, setTemplate] = useState([]);
    const [description, setDescription] = useState("");
    const [filterUser, setFilterUser] = useState("");
    const [CaseID, setCaseID] = useState("");
    const [Dates, setDates] = useState(currentDate);
    const [FileName, setFileName] = useState("");
    const [letterTemplateID, setletterTemplateID] = useState("");
    const [delId, setDelId] = useState("");
    const [active, setActive] = useState(false);
  
    //        ---------- EDIT VARIABLE --------
  
    const [editId, setEditId] = useState("");
    const [EditCaseID, setEditCaseID] = useState("");
    const [EditDate, setEditDate] = useState("");
    const [EditFileName, setEditFileName] = useState("");
    const [Editdescription, setEditDescription] = useState("");
    const [EditletterTemplateID, setEditletterTemplateID] = useState("");
    const formData = new FormData();
    const editLetterTemplates = new FormData();
  
    //        ---------- VIEW LETTER TEMPLATE --------
  
    // const getViewLetterTemplate = async () => {
    //   const [view, data, cdata] = await Promise.all([
    //     withUidApi("Letter/ViewLetter"),
    //     permission(21),
    //     getClient("Client/ViewClient"),
    //     getLetterTemp1(),
    //     getFileNo(),
    //     setLoading(true),
    //   ]);
  
    //   if (view === null) {
    //     setLoading(false);
    //     setLetterTemplatesData([]);
    //   } else {
    //     if (view === "token") {
    //       setTimeout(() => {
    //         navigate("/");
    //       }, 1000);
    //     } else {
    //       setLoading(false);
    //       setLetterTemplatesData(view);
    //     }
    //   }
  
    //   const clientData = cdata && cdata.filter((item) => item.Status === "1");
  
    //   const Mapped =
    //     clientData &&
    //     clientData.map((item) => ({
    //       value: item.Id,
    //       label: `${item.FirstName} ${item.LastName}`,
    //     }));
  
    //   setFClientOption(Mapped);
  
    //   if (
    //     data &&
    //     data.result[0].IsAdd === "0" &&
    //     data &&
    //     data.result[0].IsView === "0"
    //   ) {
    //     navigate("/unauthorized");
    //   }
  
    //   setIsAdd(data && data.result[0].IsAdd);
    //   setIsEdit(data && data.result[0].IsEdit);
    //   setIsView(data && data.result[0].IsView);
    //   setIsStatus(data && data.result[0].IsStatus);
    //   setIsDelete(data && data.result[0].IsDelete);
    // };
  
    //        ---------- DELETE LETTER  -------
  
    const deleteLetterTemplates = async () => {
      const data = await deleteUser(delId, "Letter/DeleteLetter");
      if (data === "token") {
        setTimeout(() => {
          closeDelete.click();
          navigate("/");
        }, 3000);
      }
      if (data === true) {
        closeDelete.click();
        getCaseHistory();
        getFileNo();
      }
    };
  
    //        ---------- ACTIVE DEACTIVE LETTER TEMPLATE --------
  
    const deActive = async (row) => {
      const data = await activeStatus(row, "Letter/LetterStatus");
      if (data === "token") {
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data === true) {
        getCaseHistory();
        getFileNo();
      }
    };
  
    //        ---------- DETAILS LETTER TEMPLATE --------
  
    const details = async (id) => {
      const data = await userDetails(id, "Letter/ViewLetterById");
      if (data === "token") {
        setTimeout(() => {
          closeView.click();
          navigate("/");
        }, 3000);
      } else {
        setDletterTemplateID(data.result[0].LetterTemplate);
        setDFileName(data.result[0].FileName);
        setDDate(data.result[0].Date);
        setDCaseID(data.result[0].FileNo);
        setDDescription(data.result[0].Description);
      }
    };
  
    //        ---------- DETAILS LETTER TEMPLATE --------
  
    const getById = async (id) => {
      const data = await userDetails(id, "Letter/ViewLetterById");
      if (data === "token") {
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      } else {
        setEditId(data.result[0].Id);
        setEditletterTemplateID(data.result[0].LetterTemplateID);
        setEditFileName(data.result[0].FileName);
        setEditDate(data.result[0].Date);
        setEditCaseID(data.result[0].CaseID);
        setEditDescription(data.result[0].Description);
      }
    };
  
    const formRef = useRef(null);
    const [show, setShow] = useState(false);
    const handleClose = () => {
      setShow(false);
      if (formRef.current) {
        formRef.current.reset();
      }
    };
    /** Get File Name  */
    const Case = new FormData();
    const [optionsCase, setOptionsCase] = useState([]);
  
    const [FileNo, setFileNo] = useState([]);
    const getFileNo = async () => {
      Case.append("AdminID", localStorage.getItem("id"));
      Case.append("UserID", localStorage.getItem("uId"));
      
      try {
        const response = await fetch(`${baseUrl}Event/GetFilename`, {
          method: "POST",
          body: Case,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
  
        if (data && data.result[0].message === "Success") {
          setFileNo(data.result);
          const Mapped = data.result.map((item) => ({
            value: item.CaseID,
            label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
          }));
          setOptionsCase(Mapped);
          setOptionsEvent(Mapped);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const handleSelectChange = (selectedOption) => {
      if (selectedOption === null) {
        setCaseID("");
      } else {
        setCaseID(selectedOption.value);
      }
    };
  
    const Case2 = new FormData();
    const [LetterTemp, setLetterTemp] = useState([]);
    const getLetterTemp1 = async () => {
      Case2.append("AdminID", localStorage.getItem("id"));
      try {
        const response = await fetch(`${baseUrl}Master/ViewLetterTemplate`, {
          method: "POST",
          body: Case2,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
        if (data && data.result[0].code == 200) {
          setLetterTemp(data.result);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    //        ---------- ADD LETTER --------
  
    const submitHandler = async (event) => {
      event.preventDefault();
      setSaveButton(false);
      formData.append("AdminID", localStorage.getItem("id"));
      formData.append("CreatedBy", localStorage.getItem("UserId"));
      formData.append("CaseID", id);
      formData.append("Date", Dates);
      // formData.append("FileName", FileName);
      formData.append("LetterTemplateID", letterTemplateID);
      formData.append("Description", description);
      try {
        const response = await fetch(`${baseUrl}Letter/AddLetter`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            closeButton.click();
            navigate("/");
          }, 3000);
        }
  
        if (data.result.length && data.result[0].message === "Success") {
          toast.success("Letter added successfully");
          closeButton.click();
          handleClose();
          setletterTemplateID("");
          setDates(currentDate);
          setCaseID("")
          setDescription("");
          getCaseHistory();
          getFileNo();
          clearForm();
          setSaveButton(true);
        } else {
          toast.error(data.result[0].message);
          setSaveButton(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    //        ---------- EDIT LETTER TEMPLATE --------
  
    const submitEditHandler = async (event) => {
      event.preventDefault();
      editLetterTemplates.append("ID", editId);
      editLetterTemplates.append("AdminID", localStorage.getItem("id"));
      editLetterTemplates.append("UpdatedBy", localStorage.getItem("UserId"));
      editLetterTemplates.append("CaseID", EditCaseID);
      editLetterTemplates.append("Date", EditDate);
      // editLetterTemplates.append("FileName", EditFileName);
      editLetterTemplates.append("LetterTemplateID", EditletterTemplateID);
      editLetterTemplates.append("Description", Editdescription);
      try {
        const response = await fetch(`${baseUrl}Letter/EditLetter`, {
          method: "POST",
          body: editLetterTemplates,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            closeEdit.click();
            navigate("/");
          }, 3000);
        }
  
        if (data.result.length && data.result[0].message === 'Success') {
          toast.success(" Updated successfully");
          getCaseHistory();
          getFileNo();
          closeEdit.click();
          setDescription("");
          clearForm();
        } else {
          toast.error(data.result[0].message);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
  
  
  //          ---------- SEARCH LETTER TEMPLATE --------

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseHistory();
      getFileNo();
      getLetterTemp1();
    }
  }, []);

  const [wordDescriptionData, setWordDescriptionData] = useState(null);
  const getByIdWord = async (id) => {
    const data = await userDetails(id, "Letter/ViewLetterById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      return data.result[0];
    }
  };

  // WORD
  const downloadWordFile = async (id) => {
    // console.log(wordDescriptionData);
    try {
      // Assuming getById is an asynchronous function that fetches data
      //setWordDescriptionData(null);Description
      const data = await getByIdWord(id);

      // console.log(id);
      // console.log(wordDescriptionData);
      // Check if data and data.editDescription are defined
      // if (data && data !== undefined) {
      // Sample HTML content

      var htmlContent = data;

      // Convert the HTML content to a Blob with MIME type "application/msword"
      const blob = new Blob(
        [
          `<!DOCTYPE html><html><head><meta charset="UTF-8"></head>
          <body>${htmlContent.Description}</body></html>`,
        ],
        { type: "application/msword;charset=utf-8" } // Specify character encoding
      );

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = `${wordDescriptionData}/Letter.doc`; // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary link and URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      // } else {
      //   console.error("Data or editDescription is undefined or empty");
      // }
    } catch (error) {
      console.error("Error fetching data or processing the file:", error);
    }
  };

  return (

         <DocumentTitle title="LETTER | LEGAL CRM">
    
    <> 
    <div className="wraper">
    <Header />
<div className="mainWraper">
<Sidebar />
  <div className="pageBody">
    <div className="pageContent">
      <div className="PageTitle">
        <h3>Case Detail</h3>
        <a title="Case Details"
           href={`#/view-casedetails/${id}`}
                        type="button"
                        className="btn btn-primary btn_client"
                      >
                        Back
                      </a>
      </div>
      <br/>

      <div className="caseDetail">
        <div className="form-group Details">
          <h4>Case Name : </h4>
          <p>
            {caseData.CaseName === "" ? "-" : caseData.CaseName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case No. : </h4>
          <p>
            {caseData.CaseNo === "" || caseData.CaseNo === null
              ? "-"
              : caseData.CaseNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Case Year : </h4>
          <p>
            {caseData.CaseYear === "" ||
            caseData.CaseYear === null
              ? "-"
              : caseData.CaseYear}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Court Name : </h4>
          <p>
            {caseData.CourtName === "" ||
            caseData.CourtName === null
              ? "-"
              : caseData.CourtName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case Type :</h4>
          <p>
            {caseData.CaseType === "" ||
            caseData.CaseType === null
              ? "-"
              : caseData.CaseType}
          </p>
        </div>
        <div className="form-group Details">
          <h4>File No. : </h4>
          <p>
            {caseData.FileNo === "" || caseData.FileNo === null
              ? "-"
              : caseData.FileNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Diary No. : </h4>
          <p>
            {caseData.DiaryNo === "" || caseData.DiaryNo === null
              ? "-"
              : caseData.DiaryNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Diary Year: </h4>
          <p>
            {caseData.DiaryYear === "" ||
            caseData.DiaryYear === null
              ? "-"
              : caseData.DiaryYear}
          </p>
        </div>
       

        <div className="form-group Details">
          <h4>Client Name : </h4>
          <p>
            {caseData.ClientName === "" ||
            caseData.ClientName === null
              ? "-"
              : caseData.ClientName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Client Last Name : </h4>
          <p>
            {caseData.ClientLastName === "" ||
            caseData.ClientLastName === null
              ? "-"
              : caseData.ClientLastName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Company Name : </h4>
          <p>
            {caseData.CompanyName === "" ||
            caseData.CompanyName === null
              ? "-"
              : caseData.CompanyName}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Client Mobile No. : </h4>

          <p>
            {caseData.MobileNo === "" ||
            caseData.MobileNo === null
              ? "-"
              : caseData.MobileNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Phone : </h4>
          <p>
            {caseData.Phone === "" || caseData.Phone === null
              ? "-"
              : caseData.Phone}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Email :</h4>
          <p>
            {caseData.Email === "" || caseData.Email === null
              ? "-"
              : caseData.Email}
          </p>
        </div>
        </div>
        <br/>
        <div className="report_crmgraph">
          <div className="row">
            <div className="col-md-12">
              <div className="myCard">
                <div className="cardHeader">
                  <h4>Letter</h4>
                  <a
                    style={{
                      // display: isAdd === "0" ? "none" : "block",
                    }}
                    type="button"
                    className="btn btn-primary btn_client headbtn"
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                  >
                    Add Letter <img src="assets/img/icons/add.svg" />
                  </a>
                  <a
                    href="/#/view-letter"
                    className="rytContent"
                  >
                    <img
                      src="assets/img/icons/dots.png"
                      alt=""
                      title="All Letter"
                    />
                  </a>
                </div>

                {optionsCD.letters && optionsCD.letters[0].message === "Success" ? (
                  <div className="cardBody">
                    <table className="myTable">
                      <thead>
                        <th>Action</th>
                        <th>Date </th>

                      </thead>

                      {optionsCD.letters &&
                        optionsCD.letters.length > 0 ? (
                        optionsCD.letters.map((val, index) => (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0
                                ? "row-even"
                                : "row-odd"
                            }
                          >
                            <td>

                              <div style={{ display: "flex", gap: "15px", width: "10px" }}>
                                {/* {isEdit !== "0" && ( */}
                                  <span
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleModalLongEdit"
                                    onClick={() => getById(val.Id)}
                                  >
                                    <img
                                      src="assets/img/icons/edit.svg"
                                      alt="Edit"
                                      title="Edit"
                                    />
                                  </span>
                                {/*  )} */}

                                <span
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalLongDetail"
                                  onClick={() => details(val.Id)}
                                >
                                  <img
                                    src="assets/img/icons/eye.svg"
                                    alt="Details"
                                    title="Details"
                                  />
                                </span>
                                <span>
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      // display: isStatus === "0" ? "none" : "block",
                                    }}
                                    onClick={() => deActive(val, "Letter/LetterStatus")}
                                  >
                                    {val.Status === "1" ? (
                                      <img
                                        className="thumb_icon"
                                        src="assets/img/icons/Up.svg"
                                        title="Status"
                                      />
                                    ) : (
                                      <img
                                        className="thumb_icon"
                                        src="assets/img/icons/Down.svg"
                                        title="Status"
                                      />
                                    )}
                                  </a>
                                </span>
                                {/* {isDelete !== "0" && ( */}
                                  <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setDelId(val.Id)}
                                  >
                                    <img
                                      src="assets/img/icons/delete.svg"
                                      alt="Delete"
                                      title="Delete"
                                    />
                                  </span>
                                {/*  )} */}
                              </div>
                            </td>
                            <td>{formatDate1(val.Date)}</td>
                          </tr>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </table>
                  </div>
                ) : <p className="case_detail_table">Data not found</p>}



              </div>
            </div>
          </div>
     </div> 
        </div>
        </div>
        </div>

   
     


<Footer/>


     </div>
            {/*--------- DELETE LETTER  --------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_lettertemplate"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete letter ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteLetterTemplates(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- ADD LETTER  FORM --------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Letter</h2>
                    </div>
                  </div>

                  {/* form */}
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>

                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <select disabled id="city" required>
                                          {optionsCase &&
                                            optionsCase.map((val, index) => (
                                              <option
                                                key={index}
                                                value={val.value}
                                                selected={val.value === id}
                                              >
                                                {val.label}
                                              </option>
                                            ))}
                                        </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="date"
                              name="templateName"
                              value={Dates}
                              onChange={(e) => setDates(e.target.value)}
                              required
                            />
                          </div>{" "}
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Use Template</label>

                            <a
                              className="visuald"
                              href="/#/view-letter-template"
                            >
                              +Add Template
                            </a>
                            <select
                              className="name"
                              placeholder="-- Please select template --"
                              name="cars"
                              id="cars"
                              onChange={handleLettertemp}
                            >
                              <option>
                                --Please Select only when you want to change
                                template--
                              </option>
                              {LetterTemp && LetterTemp.length > 0 ? (
                                LetterTemp.map((val, index) => (
                                  <option key={index} value={val.Id}>
                                    {val.LetterTemplate}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>

                        <ReactQuill
                          value={description}
                          theme="snow"
                          name="name"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleProcedureContentChange}
                          style={{ height: "320px" }}
                        ></ReactQuill>
                        <br />
                        <br />
                       {
                        saveButton ? (
                          <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                           
                          >
                            Cancel
                          </button>
                        </div>
                        ) : ""
                       }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- EDIT LETTER  FORM --------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Letter</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="e_popup close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <select
                              id="city"
                              disabled
                              // onChange={(e) => setEditCaseID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>

                              {optionsCase &&
                                optionsCase.map((val, index) => (
                                  <option
                                    key={index}
                                    value={val.value}
                                    selected={val.value == EditCaseID}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              type="date"
                              value={EditDate}
                              name="templateName"
                              onChange={(e) => setEditDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Use Template</label>

                            <select
                              placeholder="-- Please select template --"
                              name="cars"
                              id="cars"
                              onChange={handleLettertemp}
                            >
                              <option>
                                --Please Select only when you want to change
                                template--
                              </option>
                              {LetterTemp && LetterTemp.length > 0 ? (
                                LetterTemp.map((val, index) => (
                                  <option
                                    key={index}
                                    value={val.Id}
                                    selected={val.Id == EditletterTemplateID}
                                  >
                                    {val.LetterTemplate}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>

                        <ReactQuill
                          value={Editdescription}
                          theme="snow"
                          name="name"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleProcedureContentChange1}
                          style={{ height: "320px" }}
                        ></ReactQuill>

                        <br />
                        <br />
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- LETTER TEMPLATE DETAILS  --------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Letter Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            File No. &nbsp;: &nbsp;{" "}
                            {dCaseID == null ? "-" : dCaseID}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Date &nbsp;: &nbsp;{" "}
                            {dDate == null ? "-" : formatDate1(dDate)}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                       
                        <div className="form-group">
                          <h4>
                            Letter Template &nbsp;:&nbsp;{" "}
                            {dletterTemplateID == null
                              ? "-"
                              : dletterTemplateID}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <h4>Details :</h4>
                      </div>

                      <ReactQuill
                        value={ddescription}
                        theme="snow"
                        className="editorReact"
                        modules={modules}
                        formats={formats}
                        readOnly
                        style={{ height: "320px" }}
                      ></ReactQuill>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
            </DocumentTitle>

  )
}

export default LetterCD